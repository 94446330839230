import { List } from "./List";
import React, { Fragment } from "react";
import {
  CheckCircleIcon,
  TrashIcon,
  PencilSquareIcon,
} from "@heroicons/react/24/outline";
import { SideEnum, deleteOption, editOption } from "../state/reducers";
import { useAppDispatch, useAppSelector } from "../state/hooks";
import { Transition } from "@headlessui/react";
type OptionProps = { option: { label: string; id: string } };
export function Option({ option: { id, label } }: OptionProps) {
  const handleDoubleClick = () => setEditing(true);
  const handleClickEdit = () => setEditing(true);
  const dispatch = useAppDispatch();
  function handleSubmitEditOptionLabel(e: React.SyntheticEvent) {
    e.preventDefault();
    if (editOptionLabel === "") {
      dispatch(deleteOption({ id }));
    } else {
      dispatch(editOption({ id, label: editOptionLabel }));
    }
    setEditing(false);
  }
  function handleChangeEditOptionLabel(e: React.FormEvent<HTMLInputElement>) {
    setEditOptionLabel(e.currentTarget.value);
  }
  function handleClickDelete() {
    dispatch(deleteOption({ id }));
  }
  const reasons = useAppSelector((state) =>
    state.reasons.filter((reason) => reason.optionId === id),
  );
  const [editing, setEditing] = React.useState(false);
  const [showButtons, setShowButtons] = React.useState(false);
  const [editOptionLabel, setEditOptionLabel] = React.useState(label);
  const inputRef = React.useRef<HTMLInputElement>(null);
  const weightWidth: string = useAppSelector(() => {
    let proWeight = 0;
    let conWeight = 0;

    reasons.forEach((obj) => {
      if (obj.side === SideEnum.PRO) {
        proWeight += obj.weight + 1;
      } else if (obj.side === SideEnum.CON) {
        conWeight += obj.weight + 1;
      }
    });

    const proPercentage = (proWeight / (proWeight + conWeight)) * 100;
    return proPercentage + "%";
  });
  React.useEffect(() => {
    inputRef.current?.focus();
  }, [editing]);
  return (
    <>
      {editing ? (
        <form className="flex py-4" onSubmit={handleSubmitEditOptionLabel}>
          <input
            ref={inputRef}
            type="text"
            name={`edit-option-${id}`}
            data-test="edit-option-input"
            className="flex-1 rounded-tl border-2 border-gray-200 py-2 px-3 border-r-0 dark:border-0 dark:text-white dark:bg-white/5 dark:ring-1 dark:ring-inset dark:ring-white/10"
            onChange={handleChangeEditOptionLabel}
            value={editOptionLabel}
            onKeyUp={(e) => {
              if (e.key === "Escape") {
                setEditOptionLabel(label);
                setEditing(false);
              }
            }}
          />
          <button
            aria-label={`Save updated option label`}
            type="submit"
            className="rounded-br border-2 border-gray-200 bg-gray-200 p-2 dark:border-0  dark:bg-white/10 dark:hover:bg-white/20 dark:ring-1 dark:ring-inset dark:ring-white/10"
          >
            <CheckCircleIcon className="h-6 w-6" />
          </button>
        </form>
      ) : (
        <div>
          <h2
            onDoubleClick={handleDoubleClick}
            className="pt-4 pb-2 text-xl font-medium flex border-b border-gray-200 dark:border-white-100 "
            data-test="option-heading"
            onMouseEnter={() => setShowButtons(true)}
            onMouseLeave={() => setShowButtons(false)}
          >
            <span className="flex-1">{label}</span>
            {showButtons && (
              <>
                <button
                  data-test="option-delete-button"
                  onClick={handleClickDelete}
                  aria-label={`Delete option`}
                >
                  <TrashIcon className="h-6 w-6 text-gray-400" />
                </button>
                <button
                  data-test="option-edit-button"
                  onClick={handleClickEdit}
                  aria-label={`Edit option label`}
                >
                  <PencilSquareIcon className="h-6 w-6 text-gray-400" />
                </button>
              </>
            )}
          </h2>
        </div>
      )}
      <div>
        <div
          className={`w-full bg-red-200 dark:bg-red-900  h-5 mb-2 ${
            reasons.length === 0 ? "invisible" : "visible"
          }`}
        >
          <Transition show={true}>
            <div
              className={`transition-all bg-green-200 dark:bg-green-900 h-5 border-solid border-black ${
                weightWidth === "0%" || weightWidth === "100%"
                  ? "border-r-0"
                  : "border-r-4"
              }`}
              style={{ width: weightWidth }}
            ></div>
          </Transition>
        </div>
      </div>
      <div className="grid md:grid-cols-2 sm:grid-cols-1 flex !border-0">
        <div className="md:pr-2">
          <List
            reasons={reasons.filter((reason) => reason.side === SideEnum.PRO)}
            optionId={id}
            side={SideEnum.PRO}
          />
        </div>
        <div className="md:pl-2">
          <List
            reasons={reasons.filter((reason) => reason.side === SideEnum.CON)}
            optionId={id}
            side={SideEnum.CON}
          />
        </div>
      </div>
    </>
  );
}
