/**
 * Pros Cons List App
 */
import React, { useEffect } from "react";
import { AddOptionForm } from "@/components/AddOptionForm";
import { Option } from "@/components/Option";
import { useAppSelector } from "@/state/hooks";
import { RootState } from "@/state/store";
import { useTranslation } from "next-i18next";
import { getStaticPaths, makeStaticProps } from "@/utils/getStatic";
import { config } from "@/site.config.js";
import { useRouter } from "next/router";
import languageDetector from "@/utils/languageDetector";
import Head from "next/head";
import i18nextConfig from "@/next-i18next.config";
import { Panel } from "@/components/Panel";
function Home() {
  const router = useRouter();
  useEffect(() => {
    const cachedLocale = localStorage.getItem("i18nextLng");
    // If the default locale is set in the URL and there are no other params
    // (e.g., in example.com/en  remove locale so it's just example.com/)
    if (
      router.query.locale === i18nextConfig.i18n.defaultLocale &&
      languageDetector.cache
    ) {
      languageDetector.cache(i18nextConfig.i18n.defaultLocale);
      router.replace(router.pathname.replace(`[locale]`, ""));
    } else if (
      cachedLocale &&
      cachedLocale !== router.query.locale &&
      cachedLocale !== i18nextConfig.i18n.defaultLocale
    ) {
      router.replace({
        pathname: "/[locale]",
        query: { locale: cachedLocale },
      });
    } else if (router.query.locale && languageDetector.cache) {
      languageDetector.cache(router.query.locale.toString());
    }
  }, [router]);
  const state = useAppSelector((state: RootState) => state);
  const { t } = useTranslation();
  return (
    <>
      <Head>
        <title>{t("title")}</title>
        <meta name="description" content={t("description")} />
        <meta property="og:description" content={t("description")} />
        <meta property="og:title" content={t("title")} />
        <meta
          property="og:url"
          content={`${config.prodDomain}/${router.asPath}`}
        />
        <meta property="og:site_name" content={t("title")} />
      </Head>
      <div className="flex-1">
        <div className="max-w-3xl mx-auto">
          {state.options.map((option) => (
            <Panel key={option.id} additionalClasses="p-4">
              <Option option={option} />
            </Panel>
          ))}
          <>
            <Panel additionalClasses="p-4">
              <AddOptionForm />
            </Panel>
          </>
        </div>
      </div>
    </>
  );
}

export default Home;
const getStaticProps = makeStaticProps(["common"]);
export { getStaticPaths, getStaticProps };
