import React from "react";
import { addOption } from "../state/reducers";
import { useAppDispatch } from "../state/hooks";
import { useTranslation } from "next-i18next";

const OptionInput = ({
  handleChange,
  optionValue,
}: {
  handleChange: (e: React.FormEvent<HTMLInputElement>) => void;
  optionValue: string;
}) => {
  const { t } = useTranslation();
  return (
    <div className="flex py-4">
      <input
        data-test="option-input"
        className="flex-1 rounded-tl dark:border-0 border-2 border-gray-200 py-2 px-3 border-r-0 dark:text-white dark:bg-white/5 dark:ring-1 dark:ring-inset dark:ring-white/10 "
        type="text"
        name={"Add Option"}
        onChange={handleChange}
        placeholder={t("AddOptionForm.addOption")}
        value={optionValue}
      />
      <button
        className="rounded-br border-2 border-gray-200 bg-gray-200 p-2 dark:border-0  dark:bg-white/10 dark:hover:bg-white/20 dark:ring-1 dark:ring-inset dark:ring-white/10 "
        data-test="add-option-button"
      >
        {t("AddOptionForm.Add")}
      </button>
    </div>
  );
};

export function AddOptionForm() {
  const [optionValue, setOptionValue] = React.useState("");
  const dispatch = useAppDispatch();

  function handleOptionSubmit(e: React.SyntheticEvent) {
    e.preventDefault();
    if (optionValue === "") return;
    dispatch(addOption({ label: optionValue }));
    setOptionValue("");
  }

  function handleChange(e: React.FormEvent<HTMLInputElement>) {
    setOptionValue(e.currentTarget.value);
  }

  return (
    <form onSubmit={handleOptionSubmit}>
      <OptionInput handleChange={handleChange} optionValue={optionValue} />
    </form>
  );
}
