/* eslint-disable jsx-a11y/no-autofocus */
import React, { Fragment } from "react";
import {
  TrashIcon,
  PencilSquareIcon,
  StarIcon,
  ExclamationCircleIcon,
  MinusIcon,
  PlusIcon,
} from "@heroicons/react/24/outline";

import {
  StarIcon as StarSolidIcon,
  ExclamationCircleIcon as ExclamationSolidCircleIcon,
} from "@heroicons/react/24/solid";
import { useAppDispatch } from "../state/hooks";
import {
  ReasonType,
  SideEnum,
  addReason,
  editReason,
  editReasonWeight,
  deleteReason,
} from "../state/reducers";
import { Transition } from "@headlessui/react";
import { useTranslation } from "next-i18next";
function Reason({ side, id, label, weight }: ReasonType) {
  const dispatch = useAppDispatch();
  const [showButtons, setShowButtons] = React.useState(false);
  const [editing, setEditing] = React.useState(false);
  const [editReasonValue, setEditReasonValue] = React.useState(label);
  const maxReasonWeight = 1;
  const minReasonWeight = 0;
  function increaseReasonWeight() {
    if (weight < maxReasonWeight)
      dispatch(editReasonWeight({ id, weight: weight + 1 }));
  }
  function decreaseReasonWeight() {
    if (weight > minReasonWeight)
      dispatch(editReasonWeight({ id, weight: weight - 1 }));
  }
  function handleSubmitEditReason(e: React.SyntheticEvent) {
    e.preventDefault();
    if (editReasonValue === "") {
      dispatch(deleteReason({ id }));
    }
    dispatch(editReason({ id, label: editReasonValue }));
    setEditing(false);
  }
  function handleDeleteReason(e: React.SyntheticEvent) {
    e.preventDefault();
    dispatch(deleteReason({ id }));
  }
  const greenColorWeight = [
    "bg-green-100 dark:bg-green-900",
    "bg-green-200 dark:bg-green-700",
  ];
  const redColorWeight = [
    "bg-red-100 dark:bg-red-900",
    "bg-red-200 dark:bg-red-700",
  ];
  const borders = ["reason-weight-low", "reason-weight-high"];
  const WeightIconSelected =
    side === SideEnum.PRO ? StarIcon : ExclamationCircleIcon;
  const WeightIconUnselected =
    side === SideEnum.PRO ? StarSolidIcon : ExclamationSolidCircleIcon;
  return (
    <Transition
      as={Fragment}
      appear={true}
      show={true}
      enter="transition-opacity duration-150"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-150"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <li
        data-test={`${side}-reason`}
        className={`${
          side === SideEnum.PRO
            ? greenColorWeight[weight]
            : redColorWeight[weight]
        } dark:text-white transition-all my-1 p-1 flex list-reason ${
          borders[weight]
        }`}
        onMouseEnter={() => setShowButtons(true)}
        onMouseLeave={() => setShowButtons(false)}
      >
        <>
          {editing ? (
            <>
              <form onSubmit={handleSubmitEditReason} className={"w-full"}>
                <input
                  type="text"
                  className=" dark:border-0 dark:text-white dark:bg-white/5 dark:ring-1 dark:ring-inset dark:ring-white/10 w-full"
                  data-test="edit-reason-input"
                  onKeyUp={(e) => {
                    if (e.key === "Escape") {
                      setEditReasonValue(label);
                      setEditing(false);
                    }
                  }}
                  onChange={(e) => {
                    setEditReasonValue(e.target.value);
                  }}
                  value={editReasonValue}
                />
              </form>
            </>
          ) : (
            <>
              <button
                data-test="decrease-reason-weight-button"
                onClick={decreaseReasonWeight}
                aria-label={`Decrease reason weight`}
              >
                <WeightIconUnselected
                  className={`h-6 w-6 ${
                    weight > minReasonWeight
                      ? `text-gray-600 dark:text-white`
                      : `hidden`
                  }`}
                />
              </button>
              <button
                data-test="increase-reason-weight-button"
                onClick={increaseReasonWeight}
                aria-label={`Increase reason weight`}
              >
                <WeightIconSelected
                  className={`h-6 w-6 ${
                    weight < maxReasonWeight
                      ? `text-gray-600 dark:text-white`
                      : `hidden`
                  }`}
                />
              </button>

              <span className="md:pl-2 flex-1 ml-2 leading-5">{label}</span>
              <div className={"w-12 h-6 self-center"}>
                {showButtons && (
                  <>
                    <button
                      data-test="delete-reason-button"
                      onClick={handleDeleteReason}
                      aria-label={`Delete reason`}
                    >
                      <TrashIcon className="h-6 w-6 text-gray-400 dark:text-gray-300" />
                    </button>
                    <button
                      data-test="edit-reason-button"
                      onClick={() => {
                        setEditing(true);
                      }}
                      aria-label={`Edit reason`}
                    >
                      <PencilSquareIcon className="h-6 w-6 text-gray-400 dark:text-gray-300" />
                    </button>
                  </>
                )}
              </div>
            </>
          )}
        </>
      </li>
    </Transition>
  );
}
export function List({
  side,
  reasons,
  optionId,
}: {
  side: SideEnum;
  reasons: ReasonType[];
  optionId: string;
}) {
  const [newReason, setNewReason] = React.useState("");
  const dispatch = useAppDispatch();

  function handleSubmit(e: React.SyntheticEvent) {
    e.preventDefault();
    if (newReason === "") return;
    dispatch(addReason({ side, optionId, label: newReason }));
    setNewReason("");
  }

  function handleNewReasonChange(e: React.FormEvent<HTMLInputElement>) {
    setNewReason(e.currentTarget.value);
  }

  return (
    <div className="pb-7">
      <form onSubmit={handleSubmit} className="py-4">
        <ReasonInput
          autoFocus={side === SideEnum.PRO ? true : false}
          handleNewReasonChange={handleNewReasonChange}
          side={side}
          newReason={newReason}
        />
      </form>
      <ul className={` list-inside px-1`} data-test={`${side}-reason-list`}>
        {reasons.map(({ label, id, weight }) => (
          <Reason
            side={side}
            id={id}
            key={id}
            label={label}
            optionId={optionId}
            weight={weight}
          ></Reason>
        ))}
      </ul>
    </div>
  );
}

const ReasonInput = ({
  handleNewReasonChange,
  side,
  newReason,
  autoFocus,
}: {
  handleNewReasonChange: (e: React.FormEvent<HTMLInputElement>) => void;
  side: SideEnum;
  newReason: string;
  autoFocus?: boolean;
}) => {
  const { t } = useTranslation();

  return (
    <div className="flex">
      <input
        className="flex-1 rounded-tl border-2 border-gray-200 py-2 px-3 border-r-0 dark:border-0 dark:text-white dark:bg-white/5 dark:ring-1 dark:ring-inset dark:ring-white/10"
        data-test={`${side}-reason-input`}
        autoFocus={autoFocus ? true : false}
        type="text"
        onChange={handleNewReasonChange}
        placeholder={
          side === SideEnum.PRO ? t("List.addPro") : t("List.addCon")
        }
        value={newReason}
      />
      <button
        aria-label={side === SideEnum.PRO ? t("List.addPro") : t("List.addCon")}
        className="rounded-br border-2 border-gray-200 bg-gray-200 p-2 dark:border-0  dark:bg-white/10 dark:hover:bg-white/20 dark:ring-1 dark:ring-inset dark:ring-white/10"
      >
        {side === SideEnum.PRO ? (
          <PlusIcon className="h-6 w-6" />
        ) : (
          <MinusIcon className="h-6 w-6" />
        )}
      </button>
    </div>
  );
};
